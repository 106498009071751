import { isNotEmptyString } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  CalendarDayStatus,
  DayPriceRange,
  DayRestriction,
} from '../domain/CalendarDayStatus.model'
import { DateElementDTO } from './CalendarDayStatus.api.dto'
import { Time } from 'src/core/Shared/infrastructure/Time'

export const mapCalendarDaysStatus = (
  dates: DateElementDTO[],
): CalendarDayStatus[] => {
  return dates.map(dayStatus => {
    return {
      date: Time.fromString(dayStatus.date).toDate(),
      priceRange: (dayStatus.priceRange as DayPriceRange) ?? 'EMPTY',
      restriction: {
        status: (dayStatus.restriction.status as DayRestriction) ?? 'OPEN',
        quantity: isNotEmptyString(dayStatus.restriction.quantity)
          ? parseInt(dayStatus.restriction.quantity)
          : undefined,
      },
    }
  })
}
