import { AvailabilityRepository } from '../domain/Availability.repository'

import {
  AvailabilityDTO,
  AvailabilityExtrasDTO,
  AvailabilityExtrasRequestDTO,
  AvailabilityRequestDTO,
} from './Availability.api.dto'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import {
  isDefined,
  isEmpty,
  isUndefined,
  omit,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { serializeRequest } from './serializeRequest'
import {
  AvailabilityError,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { AxiosError, HttpStatusCode } from 'axios'
import { Time } from 'src/core/Shared/infrastructure/Time'
import {
  Analytics,
  AnalyticsErrorTracker,
} from 'src/core/Shared/domain/Analytics'
import { AvailabilityApiClient } from 'src/core/Shared/infrastructure/availabilityApiClient'
import { mapExtras } from './mapExtras'
import { mapAvailability } from './mapAvailability'
import { CalendarDayStatusDTO } from './CalendarDayStatus.api.dto'
import { mapCalendarDaysStatus } from './mapCalendarDaysStatus'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { Logger } from 'src/core/Shared/domain/Logger'

interface AllAvailabilitiesRepositoryDependencies {
  availabilityApiClient: AvailabilityApiClient
  analytics: Analytics
  logger: Logger
}

export const apiAvailabilityRepository: WithInjectedParams<
  AvailabilityRepository,
  AllAvailabilitiesRepositoryDependencies
> = ({ availabilityApiClient, analytics, logger }) => ({
  all: async request => {
    const {
      market,
      hotel: hotelId,
      checkIn,
      checkOut,
      adults,
      children,
      childrenAges,
      currency,
      membership,
      coupons,
      groupCode,
    } = request

    const params: AvailabilityRequestDTO = {
      market,
      id: hotelId,
      start_date: Time.fromDate(checkIn).format('YYYY-MM-DD'),
      end_date: Time.fromDate(checkOut).format('YYYY-MM-DD'),
      adults: adults,
      children: children,
      children_ages: childrenAges,
      membership,
      coupons: coupons?.map(coupon => normaliceCouponParam(coupon)),
      group_code: groupCode?.trim(),
    }

    try {
      const { hotel, stays } = await availabilityApiClient.get<AvailabilityDTO>(
        `/v1/hotels/${encodeURIComponent(params.id)}/rooms`,
        {
          params,
          paramsSerializer: {
            serialize: params => serializeRequest(params, childrenAges),
          },
          headers: {
            'X-CBE-Customer-Currency': currency,
          },
        },
      )

      if (stays.every(stay => isEmpty(stay.rooms))) {
        throw new AvailabilityError(
          AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR,
          `Hotel ${hotelId} not found`,
          undefined,
        )
      }

      return mapAvailability(stays, hotel)
    } catch (error) {
      if (error instanceof AxiosError) {
        callToAnalytics(error, analytics)

        if (isUndefined(error.response?.data?.code)) {
          throw error
        }

        const customError = error as AxiosError<{
          code: number
          message: string
        }>

        throw new AvailabilityError(
          customError.response?.data.code as AvailabilityErrorType,
          customError.response?.data.message ?? 'Unknown message',
          error,
        )
      }

      throw error
    }
  },
  extras: async request => {
    const {
      hotel,
      checkIn,
      checkOut,
      adults,
      children,
      childrenAges,
      roomIds,
      rateIds,
      ratesCurrencies,
      currency,
    } = request

    const params: AvailabilityExtrasRequestDTO = {
      id: hotel,
      start_date: Time.fromDate(checkIn).format('YYYY-MM-DD'),
      end_date: Time.fromDate(checkOut).format('YYYY-MM-DD'),
      adults,
      children,
      children_ages: childrenAges,
      room_ids: roomIds,
      rate_ids: rateIds,
      rates_currencies: ratesCurrencies,
    }

    try {
      const { data: extrasDTO } =
        await availabilityApiClient.get<AvailabilityExtrasDTO>(
          `/v1/hotels/${encodeURIComponent(params.id)}/extras`,
          {
            params,
            paramsSerializer: {
              serialize: params =>
                serializeRequest(omit(params, ['id']), childrenAges),
            },
            headers: {
              'X-CBE-Customer-Currency': currency,
            },
          },
        )

      return mapExtras(extrasDTO.extras)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.NotFound) {
          return []
        }
      }

      throw error
    }
  },
  twoMonthsStatus: async ({ hotelId, month, market }) => {
    const firstDayAvailable = datesManager.getFirstDayAvailable().toDate()
    const initialDay = firstDayAvailable > month ? firstDayAvailable : month

    const startDate = Time.fromDate(initialDay).format('YYYY-MM-DD')
    const endDate = Time.fromDate(month)
      .add(1, 'month')
      .endOfTime('month')
      .format('YYYY-MM-DD')

    try {
      const { data } = await availabilityApiClient.get<CalendarDayStatusDTO>(
        `/v1/hotels/${encodeURIComponent(hotelId)}/availability/by-dates`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            market: market,
          },
        },
      )

      return mapCalendarDaysStatus(data.dates)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.NotFound) {
          return []
        }
      }

      logger.error(error)
      return []
    }
  },
})

const normaliceCouponParam = (coupon: string) => {
  return coupon
    .trim()
    .replace(/%E2%80%8B/g, '')
    .replace(/​/g, '')
}

const callToAnalytics = (error: AxiosError, analytics: Analytics) => {
  if (isUndefined(error.response)) {
    analytics.errors.availability({
      code: error.code,
    })
    return
  }

  const analyticErrors: Record<number, AnalyticsErrorTracker> = {
    400: analytics.errors.availabilitySystem,
    500: analytics.errors.availabilitySystem,
    502: analytics.errors.availabilitySystem,
  }

  const analyticsFunction = analyticErrors[error.response.status]

  if (isDefined(analyticsFunction)) {
    analyticsFunction({
      code: error.code,
      status: error.response.status,
    })
    return
  }

  analytics.errors.availability({
    code: error.code,
    status: error.response.status,
    data: error.response.data,
  })
}
